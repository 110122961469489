<template>
  <div class="container">
    <div class="new-manufacturer">
      <div @click="$router.back()" id="back" class="fa fa-angle-left"></div>
      <span class="name">{{ $t("addManufacturer.newManufacturer") }}</span>
    </div>
    <div class="content">
      <form autocomplete="off">
        <div class="input-field col">
          <input id="name" type="text" v-model="manufacturer.name">
          <label for="name" class="active">{{ $t("addManufacturer.manufacturer") }}</label>
        </div>
        <div class="input-field col">
          <input id="firmName" type="text" v-model="manufacturer.firmName">
          <label for="firmName" class="active">{{ $t("addManufacturer.firmName") }}</label>
        </div>
        <div class="input-field col">
          <input id="address" type="text" v-model="manufacturer.address">
          <label for="address" class="active">{{ $t("addManufacturer.address") }}</label>
        </div>
        <div class="input-field col">
          <input id="phone" type="text" v-model="manufacturer.phone">
          <label for="phone" class="active">{{ $t("addManufacturer.phone") }}</label>
        </div>
        <div class="input-field col">
          <input id="email" type="text" v-model="manufacturer.email">
          <label for="email" class="active">{{ $t("addManufacturer.email") }}</label>
        </div>
        <div id="controller">
          <button @click="createManufacture" type="button" id="create" class="btn waves-effect waves-light">
            {{ $t("addManufacturer.create") }}<i class="fa fa-plus right"></i>
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>

import axios from "axios";
import Vue from "vue";
import VueToast from "vue-toast-notification";
import 'vue-toast-notification/dist/theme-default.css';
import Link from "../utils/Link";

export default {
  data() {
    return {
      manufacturer: {
        name: this.getDefaultManufacturerName()
      }
    };
  },

  methods: {
    showManufacturerCreateSuccess(resolve) {
      return () => {
        this.createToast(this.$t("addManufacturer.createToast", [this.manufacturer.name]), "success")
        resolve();
      };
    },

    createManufacturerRequest: function (headers, resolve) {
      return axios.post(
          Link.methods.getManufacturesCreateUrl(),
          this.manufacturer, {headers}
      ).then((res) =>
          this.showManufacturerCreateSuccess(resolve(res))
      );
    },

    createManufacture() {
      const headers = Link.methods.getHeaders();
      new Promise((resolve) => {
          this.createManufacturerRequest(
              headers,
              resolve
          ).catch(() => {
            this.createToast(this.$t("addManufacturer.manufacturerNotCreated"), "success")
          });
      }).then(() => {
        this.storeNewManufacturerName()
        this.$router.back();
      })
    },

    getDefaultManufacturerName() {
      let manufacturerName = undefined;
      let context = window.localStorage.getItem("headerTitle");
      if (context === 'items' || context === 'tickets') {
        const savedData = window.localStorage.getItem("savedItem");
        if (savedData) {
          const item = JSON.parse(savedData);
          manufacturerName = item.manufacturer;
        }
      }
      return manufacturerName;
    },

    storeNewManufacturerName() {
      let context = window.localStorage.getItem("headerTitle");
      if (context === 'items' || context === 'tickets') {
        let savedData = window.localStorage.getItem("savedItem");
        if (savedData) {
          let item = JSON.parse(savedData)
          item.manufacturer = this.manufacturer.name;
          savedData = JSON.stringify(item);
          window.localStorage.setItem("savedItem", savedData);
        }
      }
    },

    createToast(msg, type) {
      Vue.use(VueToast);
      Vue.$toast.open({
        type: type,
        message: msg,
        position: "bottom-right",
        duration: 5000
      });
    }
  }
}
</script>

<style scoped lang="scss">
@import '../../../public/materialize-src/sass/materialize.scss';
@import "public/styles/vars.scss";
@import "public/styles/addManufacturer";

#controller {
  text-align-last: center;
}

#create {
  width: -webkit-fill-available;
  background: #535353;
}

#create > i {
  color: white;
  padding: 0 4px;
}

.container {
  width: 300px;
}

.new-manufacturer {
  margin-left: 60px;
  margin-right: 6px;
  margin-bottom: 16px;
  font-size: 22px;
  grid-area: ab;
}

#back {
  color: #3283B0;
}

.name {
  font-weight: bold;
}

.fa {
  color: #535353;
  padding: 15px;
}
</style>
